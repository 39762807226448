import { fetchGet, fetchPost } from "../index";
import axios from "axios";
import downloadFile from "@/utils/claim/downloadFile";

//status下拉选框
export const getFindByStatus = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/demo/car/status_drop_box", params);
};

//获取demo car列表数据
export const getDemoCarList = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/demo/car/page_claim_generate", { params });
};

//上传文件
export const uploadDemoCar = <T>(formData: FormData): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      data: formData,
      url: "/claimapi/demo/car/upload_excel",
      //  params: params,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//导出功能
export const exportPageApi = (params = {}): void => {
  const option = {
    url: `/claimapi/demo/car/export_claim_generate`,
    method: "get",
    params,
  };
  downloadFile(option, "application/vnd-excel; char-set=UTF-8");
};

//获取单条confirm弹窗信息
export const getConfirmMsg = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/demo/car/confirm_info", { params });
};

//单条confirm事件
export const confirmDeadline = (params = {}): Promise<any> => {
  return fetchPost("/claimapi/demo/car/confirm", params);
};

//获取批量confirm弹窗信息
export const getBatchConfirmMsg = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/demo/car/batch_confirm_info", { params });
};

//批量confirm事件
export const batchConfirmDeadline = (params = {}): Promise<any> => {
  return fetchPost("/claimapi/demo/car/batch_confirm", params);
};

//获取communicate弹窗信息
export const getCommunicateMsg = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/demo/car/communicate_info", { params });
};

//communicate事件
export const communicateMail = (params = {}): Promise<any> => {
  return fetchPost("/claimapi/demo/car/communicate_to_dealer", params);
};
